<template>
  <div>
    <div class="row breadcrumbs-top">
      <div class="col-4">
        <h2 class="content-header-title float-left pr-1 mb-0">
          Mening buyumlarim
        </h2>
      </div>
      <div class="col-8">
        <div class="float-right mb-1">
          <b-overlay :show="loadingBtn">
            <b-button
              variant="outline-success"
              size="small"
              @click="tableToExcel"
            >
              <img src="/images/excel_1.svg" alt="" />
              Yuklab olish
            </b-button>
          </b-overlay>
        </div>
      </div>
    </div>
    <!--  -->
    <CategoryFilter
      ref="categoryFilter"
      :category_id="filter.category_id"
      @onSuccess="setFiter"
    />
    <b-card>
      <el-form
        ref="filter"
        label-position="top"
        label-width="100px"
        :model="filter"
        @submit="getItems"
      >
        <el-row class="mb-2" :gutter="20">
          <el-col :md="8" :lg="6">
            <label label="category_id">Категория </label>
            <v-select
              id="category_id"
              v-model="filter.parent0_category_id"
              :options="categoriesLevel0"
              :reduce="(option) => option.id"
              class="w-100"
              style="width: 100%"
              placeholder="Категория"
              label="name_uz"
              @input="parent0change($event)"
            />
          </el-col>
          <el-col v-if="filter.parent0_category_id" :md="8" :lg="6">
            <label label="category_id1">Подкатегория </label>
            <v-select
              id="category_id1"
              v-model="filter.parent1_category_id"
              :options="categoriesLevel1"
              :reduce="(option) => option.id"
              class="w-100"
              label="name_uz"
              placeholder="Подкатегория"
              @input="parent1change($event)"
            />
          </el-col>
          <el-col v-if="filter.parent1_category_id" :md="8" :lg="6">
            <label label="category_id2">Номенклатура </label>
            <v-select
              id="category_id2"
              v-model="filter.parent2_category_id"
              :options="categoriesLevel2"
              :reduce="(option) => option.id"
              placeholder="Номенклатура"
              class="w-100"
              label="name_uz"
              @input="parent2change($event)"
            />
          </el-col>
          <el-col :md="8" :lg="6">
            <label label="warehouse">Склад </label>
            <v-select
              id="warehouse"
              v-model="filter.warehouse_id"
              :options="warehouses.data"
              :reduce="(option) => option.id"
              placeholder="Склад"
              class="w-100"
              label="name_uz"
              @input="getItems"
            />
          </el-col>
          <el-col :md="8" :lg="6">
            <label label="isCostPrice">Цена</label>
            <v-select
              id="isCostPrice"
              v-model="filter.is_cost_price"
              :options="isCostOptions"
              :reduce="(option) => option.id"
              placeholder="Цена"
              class="w-100"
              label="name_uz"
              @input="getItems"
            />
          </el-col>
          <el-col :md="8" :lg="6">
            <label label="v-phone-1">Nomi bo'yicha qidiruv</label>
            <b-form-input
              id="v-phone-1"
              v-model="filter.name_uz"
              placeholder="Nomi bo'yicha qidiruv"
              @input="getItems"
            />
          </el-col>
        </el-row>
      </el-form>
      <save ref="saveForm" @onSuccess="getItems" />
      <b-overlay
        variant="white"
        spinner-variant="primary"
        blur="0"
        opacity=".75"
        rounded="sm"
        :show="loading"
      >
        <h2 v-if="filter.warehouse_id">
          {{ warehouseName }}
        </h2>
        <el-table
          v-if="!loading"
          ref="treeTable"
          :data="warehouseProducts"
          row-key="id"
          border
          :row-class-name="tableRowClassName"
        >
          <el-table-column type="index" :index="indexMethod" />
          <el-table-column label="Наименование" width="600">
            <template slot-scope="scope">
              <span class="c-pointer fs16">
                <span>
                  {{
                    [
                      scope.row.models_with_parent.parent0_name_uz,
                      scope.row.models_with_parent.parent1_name_uz,
                      scope.row.models_with_parent.parent2_name_uz,
                    ].join("/ ")
                  }}
                </span>
                <br />
                <b
                  :class="
                    scope.row.models_with_parent &&
                    scope.row.models_with_parent.cost_dollar
                      ? 'text-primary'
                      : 'text-muted'
                  "
                  @click="openModal(scope.row.category_id)"
                >
                  <i class="el-icon-star-off" />
                  {{
                    scope.row.models_with_parent
                      ? scope.row.models_with_parent.name_uz
                      : ""
                  }}
                </b>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="Количество">
            <template slot-scope="scope">
              <span
                v-if="scope.row && scope.row.quantity"
                class="fs18 font-weight-bolder"
                >{{ scope.row.quantity }}</span
              >
            </template>
          </el-table-column>
          <el-table-column label="Цена продажа">
            <template
              v-if="
                scope.row &&
                scope.row.models_with_parent &&
                scope.row.models_with_parent.price_dollar
              "
              slot-scope="scope"
            >
              <span v-if="scope.row.models_with_parent.price_dollar">
                {{
                  (Number(scope.row.models_with_parent.price_dollar) *
                    dollarRate)
                    | formatPrice
                }}
                so'm
              </span>
              <br />
              <span v-if="scope.row.models_with_parent.price_dollar">
                {{ scope.row.models_with_parent.price_dollar | formatPrice }} $
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="
              ['director', 'admin', 'deputy', 'marketer'].includes(
                user.role.name
              )
            "
            label="Цена прихода"
          >
            <template slot-scope="scope">
              <template
                v-if="
                  scope.row.models_with_parent.cost ||
                  scope.row.models_with_parent.cost_dollar
                "
              >
                <span v-if="scope.row.models_with_parent.cost">
                  {{ scope.row.cost | formatPrice }} so'm
                </span>
                <br />
                <span v-if="scope.row.models_with_parent.cost_dollar">
                  {{ scope.row.models_with_parent.cost_dollar | formatPrice }}$
                </span>
              </template>
              <template v-else>
                <span class="text-warning"> Товар не прибыл </span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="Цена рассрочка">
            <template slot-scope="scope">
              <span
                v-if="scope.row.models_with_parent.price_dollar_installment"
              >
                {{
                  scope.row.models_with_parent.price_dollar_installment
                    | formatPrice
                }}$
              </span>
            </template>
          </el-table-column>
          <el-table-column width="250">
            <template slot-scope="scope">
              <b-button
                v-if="
                  scope.row &&
                  scope.row.models_with_parent.cost_dollar &&
                  !['marketer', 'hired_worker'].includes(user.role.name)
                "
                v-can="'settings.product_categories-update-price'"
                size="sm"
                variant="outline-primary mr-1"
                @click="addCategory(scope.row)"
              >
                Изменить цену
              </b-button>

              <b-button
                size="sm"
                variant="outline-info mt-1"
                @click="
                  $router.push({
                    name: 'ProductInfo',
                    params: { id: scope.row.models_with_parent.id },
                  })
                "
              >
                <!-- @click="showProductModal=true" -->
                Редактировать
              </b-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-else style="height: 550px" />
      </b-overlay>
      <!--  -->
      <el-pagination
        background
        :total="filter.total"
        :page-size="1 * filter.per_page"
        layout="prev, pager, next"
        class="mt-3"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <!--  -->
      <!-- Excel Table -->
      <el-table
        v-show="false"
        v-if="!loading"
        class="excelTable"
        :data="excelWarehouseProducts"
        row-key="id"
        border
        :row-class-name="tableRowClassName"
      >
        <el-table-column type="index" :index="indexMethod" />
        <el-table-column label="Наименование" width="600">
          <template slot-scope="scope">
            <span class="c-pointer fs16">
              <span>
                {{
                  [
                    scope.row.models_with_parent.parent0_name_uz,
                    scope.row.models_with_parent.parent1_name_uz,
                    scope.row.models_with_parent.parent2_name_uz,
                  ].join("/ ")
                }}
              </span>
              <b
                :class="
                  scope.row.models_with_parent &&
                  scope.row.models_with_parent.cost_dollar
                    ? 'text-primary'
                    : 'text-muted'
                "
                @click="openModal(scope.row.category_id)"
              >
                <i class="el-icon-star-off" />
                {{
                  scope.row.models_with_parent
                    ? scope.row.models_with_parent.name_uz
                    : ""
                }}
              </b>
            </span>
          </template>
        </el-table-column>
        <el-table-column label="Количество">
          <template slot-scope="scope">
            <span
              v-if="scope.row && scope.row.quantity"
              class="fs18 font-weight-bolder"
              >{{ scope.row.quantity }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="Цена продажа">
          <template
            v-if="
              scope.row &&
              scope.row.models_with_parent &&
              scope.row.models_with_parent.price_dollar
            "
            slot-scope="scope"
          >
            <span v-if="scope.row.models_with_parent.price_dollar">
              {{
                (Number(scope.row.models_with_parent.price_dollar) * dollarRate)
                  | formatPrice
              }}
              so'm
            </span>
            <br />
            <span v-if="scope.row.models_with_parent.price_dollar">
              {{ scope.row.models_with_parent.price_dollar | formatPrice }} $
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            ['director', 'admin', 'deputy', 'marketer'].includes(user.role.name)
          "
          label="Цена прихода"
        >
          <template slot-scope="scope">
            <template
              v-if="
                scope.row.models_with_parent.cost ||
                scope.row.models_with_parent.cost_dollar
              "
            >
              <span v-if="scope.row.models_with_parent.cost">
                {{ scope.row.cost | formatPrice }} so'm
              </span>
              <br />
              <span v-if="scope.row.models_with_parent.cost_dollar">
                {{ scope.row.models_with_parent.cost_dollar | formatPrice }}$
              </span>
            </template>
            <template v-else>
              <span class="text-warning"> Товар не прибыл </span>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="Цена рассрочка">
          <template slot-scope="scope">
            <span v-if="scope.row.models_with_parent.price_dollar_installment">
              {{
                scope.row.models_with_parent.price_dollar_installment
                  | formatPrice
              }}$
            </span>
          </template>
        </el-table-column>
      </el-table>
      <!-- End of excel table  -->
      <b-modal
        id="modal-lg"
        v-model="showPartyModal"
        size="xl"
        cancel-variant="outline-secondary"
        hide-footer
        no-close-on-backdrop
        centered
        @close="showPartyModal = false"
      >
        <div class="hello">
          <WarehouseProductInfo
            :p-category-id="pCategoryId"
            :is-component="true"
          />
        </div>
      </b-modal>
    </b-card>
    <!--Show Product  -->
    <!-- <b-modal
      id="modal-lg"
      v-model="showProductModal"
      size="xl"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      centered
      @close="showProductModal = false"
    >
      <div class="hello">
        <WarehouseProductShow
          :p-category-id="pCategoryId"
          :is-component="true"
        />
      </div>
    </b-modal> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { setItem } from "@/utils/storage";
import CategoryFilter from "@/components/categoryFilter.vue";
import vSelect from "vue-select";
import Save from "../save.vue";

import "v-dock-menu/dist/v-dock-menu.min.css";
import { showToast } from "@/utils/toast";

export default {
  name: "Index",
  components: {
    Save,
    vSelect,
    CategoryFilter,
    WarehouseProductInfo: () =>
      import("@/views/warehouse/products/warehouseProductInfo.vue"),
  },
  props: {},
  data() {
    return {
      showPartyModal: false,
      showProductModal: false,
      page: 1,
      loading: false,
      items: [],
      isCostOptions: [
        { name_uz: "Narx kiritilgan", id: 0 },
        { name_uz: "Narx kiritilmagan", id: 1 },
      ],
      loadingBtn: false,
      warehouseProducts: [],
      categoriesLevel0: [],
      categoriesLevel1: [],
      categoriesLevel2: [],
      pagination: {
        page: 1,
        per_page: 50,
        total: 0,
      },
      filter: {
        per_page: 50,
        total: 0,
        page: 1,
        name_uz: null,
        category_id: null,
        parent0_category_id: null,
        parent1_category_id: null,
        parent2_category_id: null,
        warehouse_id: null,
        is_cost_price: null,
        category_name: "",
      },
      selected: null,
      dollarRate: 0,
      action: false,
      pCategoryId: null,
      defaultExpandAll: false,
      excelWarehouseProducts: [],
      //
      uri: "data:application/vnd.ms-excel;base64,",
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p]);
      },
    };
  },
  computed: {
    ...mapGetters({
      product_categories: "productCategory/GET_ITEMS",
      treeNodes: "productCategory/GET_TREE",
      warehouses: "warehouse/GET_ITEMS",
    }),
    user() {
      return this.$store.getters["auth/USER"];
    },
    warehouseName() {
      let name = "";
      if (this.warehouses && this.warehouses.data) {
        const wh = this.warehouses.data.find(
          (warehouse) => warehouse.id == this.filter.warehouse_id
        );
        name = wh.name_uz;
      }
      return name;
    },
    today() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = today.getFullYear();
      return `${dd}-${mm}-${yyyy}`;
    },
  },
  watch: {},
  mounted() {
    this.getCategoriesByLevel(0);

    if (this.$route.query.parent0_category_id) {
      this.filter.parent0_category_id = Number(
        this.$route.query.parent0_category_id
      );
    }
    if (this.$route.query.parent1_category_id) {
      this.getCategoriesByLevel(
        2,
        Number(this.$route.query.parent1_category_id)
      );
      this.filter.parent1_category_id = Number(
        this.$route.query.parent1_category_id
      );
    }
    if (this.$route.query.parent2_category_id) {
      this.filter.parent2_category_id = Number(
        this.$route.query.parent2_category_id
      );
      this.getCategoriesByLevel(
        3,
        Number(this.$route.query.parent2_category_id)
      );
    }
    this.getDollarRates({ current_dollar_rate: 1 }).then((res) => {
      if (res.data) {
        this.dollarRate = res.data.data[0]?.amount;
      }
    });
    this.getWarehouses({ per_page: 999 });
  },
  methods: {
    ...mapActions({
      getWarehouses: "warehouse/index",
      getItemsAction: "productCategory/tree",
      getCategories: "productCategory/index",
      getDollarRates: "resource/getDollarRates",
      getWarehouseProducts: "productCategory/warehouseProducts",
    }),
    async getItems() {
      this.selected = null;
      this.loading = true;
      await this.getWarehouseProducts(this.filter).then(({ data }) => {
        this.warehouseProducts = data.data;
        this.filter.total = data.total;
        this.filter.page = data.current_page;
      });

      this.loading = false;
    },
    indexMethod(index) {
      return (
        (Number(this.filter.page) - 1) * Number(this.filter.per_page) +
        index +
        1
      );
    },
    handleCurrentChange(val) {
      this.filter.page = val;
      this.getItems();
    },
    handleSizeChange(val) {
      this.filter.per_page = val;
      this.getItems();
    },
    setFiter(arr) {
      if (arr.length) {
        const parent = arr[0];
        this.filter.category_name = arr[0].name_uz;
        if (parent) {
          this.filter[`parent${parent.level}_category_id`] = parent.id;
          this.getItems();
        }
      }
    },
    clearFilter() {
      this.filter.category_name = "";
      this.filter.category_id = null;
      this.getItems();
    },
    openModal(id) {
      this.showPartyModal = true;
      this.pCategoryId = id;
    },
    expandTable() {
      this.loading = true;
      if (this.defaultExpandAll) {
        setItem("productTableExpandAll", false);
        this.defaultExpandAll = false;
      } else {
        this.defaultExpandAll = true;
        setItem("productTableExpandAll", true);
      }
      setTimeout(() => {
        this.loading = false;
      }, 0);
    },
    tableRowClassName({ row, rowIndex }) {
      if (this.getWhProduct(row)) {
        if (this.getWhProduct(row).models_with_parent.is_cost_price === 1) {
          return "warning-row";
        }
        if (this.getWhProduct(row).models_with_parent.is_cost_price === 0) {
          return "success-row";
        }
      }
      return "";
    },
    select(item, isSelect) {
      this.selected = isSelect ? item : null;
    },
    selectedRow(item) {
      this.selected = item;
    },
    addCategory(selected) {
      if (selected) {
        this.$refs.saveForm.add(selected);
      } else {
        this.$refs.saveForm.visible = true;
      }
    },
    getWhProduct(row) {
      return this.warehouseProducts.find(
        (el) => el.category_id === row.category_id
      );
    },
    async parent0change(event) {
      this.filter.parent1_category_id = null;
      this.filter.parent2_category_id = null;
      this.filter.page = 1;
      this.changeQuery("parent0_category_id", event);
      await this.getCategoriesByLevel(1, event);
    },
    parent1change(event) {
      this.filter.parent2_category_id = null;
      this.filter.page = 1;
      this.getCategoriesByLevel(2, event);
      this.changeQuery("parent1_category_id", event);
    },
    parent2change(event) {
      this.filter.page = 1;
      this.changeQuery("parent2_category_id", event);
      this.getItems();
    },
    getCategoriesByLevel(level, parentId = null) {
      this.loading = true;
      this.getCategories({ level, parent_id: parentId, per_page: 9999 })
        .then((res) => {
          this[`categoriesLevel${level}`] = res.data.data;
        })
        .finally(() => {
          this.getItems();
          this.loading = false;
        });
    },
    tableToExcel(table) {
      if (this.filter.parent0_category_id) {
        this.loadingBtn = true;
        const filterExcel = { ...this.filter };
        filterExcel.per_page = 9999;
        filterExcel.page = 1;
        this.getWarehouseProducts(filterExcel)
          .then(({ data }) => {
            this.excelWarehouseProducts = data.data;
          })
          .finally(() => {
            const tableeee = document.querySelector(
              "div.excelTable div.el-table__body-wrapper table"
            );
            const headerThead = document.createElement("thead");
            const headerRow = document.createElement("tr");
            const headercell1 = document.createElement("th");
            headercell1.textContent = "";
            const headercell2 = document.createElement("th");
            headercell2.textContent = "Наименование";
            const headercell3 = document.createElement("th");
            headercell3.textContent = "Количество";
            const headercell4 = document.createElement("th");
            headercell4.textContent = "Цена продажа";
            const headercell5 = document.createElement("th");
            headercell5.textContent = "Цена прихода";
            const headercell6 = document.createElement("th");
            headercell6.textContent = "Цена рассрочка";
            headerRow.appendChild(headercell1);
            headerRow.appendChild(headercell2);
            headerRow.appendChild(headercell3);
            headerRow.appendChild(headercell4);
            headerRow.appendChild(headercell5);
            headerRow.appendChild(headercell6);
            headerThead.appendChild(headerRow);

            const tbody = tableeee.getElementsByTagName("tbody")[0];
            tableeee.insertBefore(headerThead, tbody);

            setTimeout(() => {
              if (!table.nodeType) table = tableeee;
              const ctx = {
                worksheet: `Mening buyumlarim(${this.today})`,
                table: table.innerHTML,
              };
              const link = document.createElement("a");
              link.download = `Mening buyumlarim(${this.today}).xls`;
              link.href =
                this.uri + this.base64(this.format(this.template, ctx));
              link.click();
              this.loadingBtn = false;
            }, 100);
          });
      } else {
        showToast("warning", "Kategoriya filterlarini tanlang");
      }
    },
  },
};
</script>

<style>
.node-title {
  font-size: 18px;
}
.el-table .warning-row {
  background: #fffcf6;
}

.el-table .success-row {
  background: #e9fff2;
}
.menu-bar-container {
  position: absolute !important;
}
</style>
